import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import Login from "./cmp/forms/Login";
import Dashboard from "./cmp/pages/Dashboard";
import Users from "./cmp/pages/Students/Students";
// import Courses from "./cmp/pages/Parents/Parents";
import Skills from "./cmp/pages/Skills/Skills";
import Students from "./cmp/pages/Students/Students";
import Parents from "./cmp/pages/Parents/Parents";
import Resumes from "./cmp/pages/Jobs/Resumes";
import Quiz from "./cmp/pages/Quiz/Quiz";
import Exams from "./cmp/pages/Exams/Exams";
import Courses from "./cmp/pages/courses/Courses";
import University from "./cmp/pages/university/university";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleCourse from "./cmp/pages/courses/SingleCourse";
import Notifications from "./cmp/pages/notifications/Notifications";
import Single from "./cmp/pages/notifications/Single";
import Semesters from "./cmp/pages/semesters/Semesters";
import SingleSem from "./cmp/pages/semesters/SingleSem";
import SingleSubject from "./cmp/pages/semesters/SingleSubject";
import Subjects from "./cmp/pages/subjects/Subjects";
import ViewExamsByCourse from "./cmp/pages/courses/ViewExamsByCourse";
import Premium from "./cmp/pages/Premium/Premium";
import StudentPremium from "./cmp/pages/StudentPremium/StudentPremium";
import AllQuiz from "./cmp/pages/Quiz/AllQuiz";
import Entrepreneurship from "./cmp/pages/Entrepreneurship/Entrepreneurship";
import Roadmaps from "./cmp/pages/Roadmaps/Roadmaps";
import Blogs from "./cmp/pages/Entrepreneurship/Blogs";
import MockInterview from "./cmp/pages/MockInterviews/MockInterview";
import StudyAbroadData from "./cmp/pages/StudyAbroadData/StudyAbroadData";
import StudyAbroadRequests from "./cmp/pages/StudyAbroadRequets/StudyAbroadRequests";
import CourseContent from "./cmp/pages/courseContent/courseContent";
import Webinar from "./cmp/pages/webinar/webinar";
import Hackathon from "./cmp/pages/hackathons/hackathon";
import Jobs from "./cmp/pages/Jobs/jobs";
import Account_deletions_requests from "./cmp/pages/AccountDeletionRequests/account_deletions_requests";
import InterviewPrep from "./cmp/pages/InterviewPrep/InterviewPrep";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/resumes" element={<Resumes />} />
        <Route path="/students" element={<Students />} />
        <Route path="/parents" element={<Parents />} />
        <Route path="/quiz/:quizId" element={<Quiz />} />
        <Route path="/allquiz" element={<AllQuiz />} />
        <Route path="/exams" element={<Exams />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/university" element={<University/>} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/studentpremium" element={<StudentPremium />} />
        <Route path="/singleCourse/:id" element={<SingleCourse />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/single/:id" element={<Single />} />
        <Route path="/semesters" element={<Semesters />} />
        <Route path="/singleSemester/:id" element={<SingleSem />} />
        <Route path="/singleSubject/:id" element={<SingleSubject />} />
        <Route path="/subjects" element={<Subjects />} />
        <Route path="/viewSubjectByCourse/:id" element={<Subjects />} />
        <Route path="/viewExamByCourse/:id" element={<ViewExamsByCourse />} />
        <Route path="/entrepreneurship" element={<Entrepreneurship />} />
        <Route path="/blogs/:title/:id" element={<Blogs />} />
        <Route path="/roadmaps" element={<Roadmaps />} />
        <Route path="/mockinterviews" element={<MockInterview />} />
        <Route path="/studyabroaddata" element={<StudyAbroadData />} />
        <Route path="/interviewPrep" element={<InterviewPrep />} />
        <Route path="/studyabroadrequests" element={<StudyAbroadRequests />} />
        <Route path="/coursecontent" element={<CourseContent />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/hackathon" element={<Hackathon />} />
        <Route path="/accountDeletionRequests" element={<Account_deletions_requests />} />
      </Routes>
    </>
  );
}

export default App;
