import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import { useParams } from "react-router-dom";
import Baseurl from "../../../Baseurl";
import auth from "../../../Auth";
import { FaTrash, FaRegEye, FaEdit } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [popup, setPopup] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const { title, id } = useParams();
  const [image, setImage] = useState();
  const [blogTitle, setBlogTitle] = useState();
  const [blogId, setBlogId] = useState();
  const [content, setContent] = useState();
  const [addLinkPopup, setAddLinkPopup] = useState(false);
  const [linkTitle, setLinkTitle] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const [relatedLinks, setRelatedLinks] = useState([]);

  const getBlogs = async () => {
    const url = Baseurl() + `entrepreneurship/getBlogsByCategory/${id}`;
    try {
      const res = await axios.get(url, auth);
      console.log("res", res.data.data);
      setBlogs(res.data.blogs);
    } catch (err) {
      console.log("err", err);
    }
  };

  const blogDelete = async (id) => {
    const url = Baseurl() + `entrepreneurship/deleteBlog/${id}`;
    try {
      const res = await axios.delete(url, auth);
      getBlogs();
      console.log("get sem by id data ", res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const blogUpdate = async (id) => {
    const url = Baseurl() + `entrepreneurship/updateBlog`;
    // Construct JSON body instead of FormData
    const payload = {
      pdfLink: pdfLink, // Assuming pdfLink is a string
      id: blogId, // Assuming blogId is a string
      relatedLinks:  JSON.stringify(relatedLinks), // Assuming relatedLinks is an array or string
    };
    try {
      axios.post(url, payload, auth).then((res) => {
        console.log(res);
        toast("Blog Updated Sucessfully");
        setUpdatePopup(false);
        getBlogs();
        setRelatedLinks([]);
        setPdfLink("");
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const addBlog = (e) => {
    e.preventDefault();
    const url = Baseurl() + "entrepreneurship/addBlog";

    const fd = new FormData();
    fd.append("title", blogTitle);
    fd.append("data", content);
    fd.append("image", image);
    fd.append("categoryId", id);
    fd.append("pdfLink", pdfLink);
    fd.append("relatedLinks",  JSON.stringify(relatedLinks));

    axios
      .post(url, fd, auth)
      .then((res) => {
        console.log(res);
        toast("Blog Added Sucessfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const addRelatedLink = () => {
    if (linkTitle.length != 0 && linkUrl.length != 0) {
      let link = {
        title: linkTitle,
        url: linkUrl,
      };
      setLinkTitle("");
      setLinkUrl("");
      setAddLinkPopup(false);
      setRelatedLinks((relatedLinks) => [...relatedLinks, link]);
    }
  };

  const removeRelatedLink = (index) => {
    let links = relatedLinks;
    links.splice(index, 1);
    setRelatedLinks(links);
  };

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          {title}
        </span>
        <button
          onClick={() => {
            setPopup(!popup);
          }}
          className="md:py-2 px-3 md:px-4 py-1 rounded-sm bg-blue-400 text-white tracking-wider"
        >
          Add Blog
        </button>
      </div>
      
      {/* Update */}
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          updatePopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh", overflow: "auto" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">Update Blog</span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setBlogId("");
                  setUpdatePopup(false);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={(e) => {
              blogUpdate(e);
            }}
          >
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                PDF Link
              </label>
              <input
                id="name"
                required
                value={pdfLink}
                onChange={(e) => {
                  setPdfLink(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Related Links &nbsp; &nbsp;
                <button
                  onClick={() => {
                    setAddLinkPopup(true);
                  }}
                  type="button"
                  className="bg-indigo-500 cursor-pointer w-7 hover:bg-indigo-600 py-1 rounded-full text-white"
                >
                  +
                </button>
              </label>
              //* Show Added related links
              {relatedLinks.map((link, index) => {
                return (
                  <div className="flex row">
                    <a
                      className="text-red-800 mb-1.5 tracking-wider font-semibold text-l w-fit"
                      href={link.url}
                      target="_blank"
                    >
                      {link.title}
                      &nbsp; &nbsp;
                    </a>
                    <button
                      onClick={() => {
                        removeRelatedLink(index);
                      }}
                      type="button"
                      className="bg-red-500 cursor-pointer w-8 h-8 hover:bg-red-600 py-1 rounded-full text-white"
                    >
                      -
                    </button>
                  </div>
                );
              })}
            </div>

            <button
              type="submit"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Update
            </button>
          </form>
        </div>
      </section>
      
      {/* Add */}
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          popup
            ?  "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh", overflow: "auto" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">Add Blogs</span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setPopup(false);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={(e) => {
              addBlog(e);
            }}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Title
              </label>
              <input
                id="name"
                required
                value={blogTitle}
                onChange={(e) => {
                  setBlogTitle(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Content
              </label>

              <textarea
                onChange={(e) => {
                  setContent(e.target.value);
                }}
                value={content}
                required
                name="content"
                id="name"
                cols="30"
                rows="4"
                className="bg-gray-100 text-gray-800 tracking-wider text-sm py-1 px-2 outline-indigo-400"
              ></textarea>
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                PDF Link
              </label>
              <input
                id="name"
                required
                value={pdfLink}
                onChange={(e) => {
                  setPdfLink(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Image
              </label>
              <input
                accept="image/*"
                id="name"
                onChange={(e) => setImage(e.target.files[0])}
                required
                type="file"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Related Links &nbsp; &nbsp;
                <button
                  onClick={() => {
                    setAddLinkPopup(true);
                  }}
                  type="button"
                  className="bg-indigo-500 cursor-pointer w-7 hover:bg-indigo-600 py-1 rounded-full text-white"
                >
                  +
                </button>
              </label>
              //* Show Added related links
              {relatedLinks.map((link, index) => {
                return (
                  <div className="flex row">
                    <a
                      className="text-red-800 mb-1.5 tracking-wider font-semibold text-l w-fit"
                      href={link.url}
                      target="_blank"
                    >
                      {link.title}
                      &nbsp; &nbsp;
                    </a>
                    <button
                      onClick={() => {
                        removeRelatedLink(index);
                      }}
                      type="button"
                      className="bg-red-500 cursor-pointer w-8 h-8 hover:bg-red-600 py-1 rounded-full text-white"
                    >
                      -
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              type="submit"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>
      
      {/* Links */}
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          addLinkPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">
              Add Related Link
            </span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setAddLinkPopup(!addLinkPopup);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={() => {}}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Title
              </label>
              <input
                id="name"
                required
                value={linkTitle}
                onChange={(e) => {
                  setLinkTitle(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                URL
              </label>
              <input
                id="name"
                required
                value={linkUrl}
                onChange={(e) => {
                  setLinkUrl(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              onClick={() => {
                addRelatedLink();
              }}
              type="button"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
              <th className="px-4 py-3  tracking-widest font-medium md:text-base text-sm rounded-tl-lg ">
                Image
              </th>
              <th className="px-4 py-3  tracking-widest font-medium md:text-base text-sm rounded-tl-lg ">
                Title
              </th>
              <th className="px-4 py-3  tracking-widest font-medium md:text-base text-sm rounded-tl-lg ">
                PDF Link
              </th>
              <th className="px-4 py-3  tracking-widest font-medium md:text-base text-sm rounded-tl-lg ">
                Edit
              </th>
              <th className="px-4 py-3  tracking-widest font-medium md:text-base text-sm rounded-tl-lg ">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((data) => {
              return (
                <tr className="tracking-wider bg-[rgb(30,39,66)] ">
                  <td className="px-4 py-2 w-36 md:w-40">
                    <img
                      src={data.image}
                      alt=""
                      className="w-14 md:w-14 h-14 md:h-14 object-cover "
                    />
                  </td>
                  <td className="px-2 py-3 md:text-base text-sm ">
                    {data.title}
                  </td>
                  <td className="px-2 py-3 md:text-base text-sm ">
                    {data.pdfLink}
                  </td>
                  <td>
                    <FaEdit
                      onClick={() => {
                        setBlogId(data._id);
                        setUpdatePopup(true);
                      }}
                      className="text-red-600 text-lg md:text-xl cursor-pointer"
                    />
                  </td>
                  <td>
                    <FaTrash
                      onClick={() => blogDelete(data._id)}
                      className="text-red-600 text-lg md:text-xl cursor-pointer"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(Blogs);
